import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import Swal from 'sweetalert2'

function ResetLink() {

    const navigate = useNavigate();

    const { token } = useParams() // Get token from URL parameters

    const [email, setEmail] = useState('') // Added state for email
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState() // Added state for confirm password

    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const response = await axios.get(`https://api.bulkbuyme.com/public/api/password-reset/${token}`);
                setEmail(response.data.email); // Assuming the API returns an object with an 'email' property
            } catch (error) {
                
            }
        };

        fetchEmail();
    }, [token]); // Fetch email when the component mounts

    const resetPassword = async (e) => {
        e.preventDefault();

        // Validate that newPassword and confirmPassword match
        if (newPassword !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Passwords do not match!',
            });
            return;
        }

        const formData = new FormData();
        
        // Append email and token to the formData
        formData.append('email', email); // Include email
        formData.append('token', token); // Include token
        formData.append('password', newPassword); // Include new password

        await axios.post('https://api.bulkbuyme.com/public/api/password/reset', formData).then(({ data }) => {
            console.log(data);
            Swal.fire('Password Changed Successfully');
            navigate("/home");
        }).catch(({ response }) => {
            console.log(response);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Email !!',
            });
        });
    }

    return (
        <>
            <Container style={{ backgroundColor: "#FFF" }} fluid>                
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">                             
                        <h3>Create New Password</h3>
                        <hr />
                    </div>
                </div>
                <Form onSubmit={resetPassword}>
                    <Row>                        
                        <Col md={6}>
                            <FormGroup>
                                <Label for="newPassword">
                                    New Password 
                                </Label>
                                <Input
                                    id="newPassword"
                                    name="newPassword"
                                    required
                                    placeholder="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(event) => {
                                        setNewPassword(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}> {/* Added new column for confirm password input */}
                            <FormGroup>
                                <Label for="confirmPassword">
                                    Confirm Password
                                </Label>
                                <Input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    required
                                    placeholder="Confirm Password"
                                    type="password"
                                    value={confirmPassword} // Bind confirmPassword state
                                    onChange={(event) => {
                                        setConfirmPassword(event.target.value) // Update confirmPassword state
                                    }}
                                />
                            </FormGroup>
                        </Col>                        
                    </Row>
                    <Button className="logout-button border-radius-15">
                        Reset
                    </Button>
                </Form>                
                <NavBarBottom />
            </Container>
        </>
    )
}
export default ResetLink;
