import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import Swal from "sweetalert2";

function DealRedeem() {

    const navigate = useNavigate();

    const { id } = useParams()
    const [qty, setQty] = useState("");
    const [pin, setPIN] = useState("1");
    const [img, setImage] = useState("")
    const [title, setTitle] = useState("")
    const [remain, setRemain] = useState("")
    const [expiry, setExpiry] = useState("")

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchDeals()
    }, [])

    const fetchDeals = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deal_redeem/${id}`).then(({ data }) => {
            //console.log(data)
            const { title, image, remain, expiry_date } = data.deals
            setRemain(remain)
            setExpiry(expiry_date)
            setTitle(title)
            setImage(image)
            setLoading(true)
        })
    }

    const redeem = async (e) => {
        e.preventDefault();

        Swal.fire({
            title: `Qty: ${qty}`,
            text: "Do you want to confirm?",
            showCancelButton: true,
            confirmButtonText: 'Yes', 
            confirmButtonColor: '#008000',           
        }).then((result) => {
            
            if (result.isConfirmed) {

                const formData = new FormData()

                formData.append('qty', qty)
                formData.append('pin', pin)
                formData.append('remain', remain)
                formData.append('cus_deal_id', id)

                axios.post('https://api.bulkbuyme.com/public/api/redeem', formData).then(({ data }) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Redemption Successful!',
                        html: data.message,  // Use the data.message as HTML content
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#3085d6',
                    }).then(() => {
                        navigate("/home");
                    });
                }).catch(({ response }) => {
                    //console.log(response)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Invalid PIN or Qty!!',
                    })
                })

            } else if (result.isDenied) {

            }
        })
    }

    return (
        <>
            <Container className="bg-light shadow" style={{ backgroundColor: "#fff", height: "100%" }}>
                <div className="row" style={{ padding: "10px", backgroundColor: "#fff" }}>
                    <div className="col-12" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#000" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                    <div className="col-6" style={{ paddingTop: "10px" }}>
                        <h3 style={{ color: "#666" }}></h3>
                    </div>
                </div>
                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                    <div className="col-3" style={{ padding: "5px" }}>
                        <img className="border-radius-15" style={{ width: "100%", backgroundColor: "#CCC", padding: "5px" }}
                            alt="Sample"
                            src={img}
                        />
                    </div>
                    <div className="col-9">
                        <h4 style={{ color: "#2B2B2B" }}>{title}</h4>
                    </div>
                </div>

                <div className="row gr" style={{ border: "solid #ccc 0px", padding: "10px", marginBottom: "20px" }}>
                    <div className="col-9" style={{ textAlign: "center", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Please ask CASHIER to <br />
                        Enter their PIN
                    </div>
                    <div className="col-3 border-radius-15 shadow" style={{ backgroundColor: "#FFF", paddingTop: "10px", border: "0px solid orange", textAlign: "center" }}>
                        <h3 style={{ color: "RED", marginBottom: "0px" }}>{remain}</h3>
                        <p className="border-radius-15 gr-orange" style={{ color: "#FFF", fontSize: "12px", padding: "5px" }}>Remaining</p>
                    </div>
                </div>

                <Form onSubmit={redeem}>
                    <Row className="mydeal-box border-radius-15" style={{ backgroundColor: "#FFF", paddingTop: "10px", paddingBottom: "10px" }}>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Qty
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="qty"
                                    value={qty}
                                    onChange={(event) => {
                                        setQty(event.target.value)
                                    }}
                                    type="number"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    PIN
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="pin"
                                    placeholder=""
                                    type="number"
                                    required
                                    onChange={(event) => {
                                        setPIN(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <Button className="logout-button border-radius-15">
                                Redeem
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div className="row" style={{ padding: "10px" }}>
                    <div className="col-12" style={{ textAlign: "center" }}>
                        <p style={{ color: "#666", fontSize: "10px", padding: "0" }}>Valid Till {expiry}</p>
                    </div>
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default DealRedeem;

