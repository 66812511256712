import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";


function Companies() {

    const { id } = useParams()
    const [user, setUser] = useState();

    const [search, setSearch] = useState("");
    const [title, setTitle] = useState("Search");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/rescat/${id}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    const fetchCompaniesSer = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/search/${search}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    return (
        <>
            <div className="bg-light">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#fff" }}>{title}</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>


                <div className="row mydeal-box" style={{ marginBottom: "20px", marginTop: "11%" }}>
                    <div className="col-12">
                        <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>Search</p>
                        <div className="col-12">
                            <Input
                                type="text"
                                style={{
                                    fontSize: '14px', // Adjust font size
                                    fontFamily: 'Arial, sans-serif', // Change to your preferred font
                                }}
                                placeholder="Search for Restaurants, Spas, Coffees, Burgers, Cusines etc..."
                                onKeyUp={(event) => {
                                    setSearch(event.target.value);
                                    fetchCompaniesSer();
                                }}
                                onFocus={(e) => e.target.style.color = '#999'} // Optional: change color on focus
                                onBlur={(e) => e.target.style.color = 'inherit'} // Reset color on blur
                            />
                            <style jsx>
                                {`  input::placeholder {
                                font-size: 12px; // Smaller placeholder font size
                                color: #999; // Placeholder color
                                font-family: Arial, sans-serif; // Match font family } `}
                            </style>
                        </div>

                    </div>
                </div>
                {
                    loading ?
                        comps.length > 0 && (
                            comps.map((row, key) => (
                                // <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.id}`}>
                                //     <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                //         <div className="col-3">
                                //             <img className="border-radius-15" style={{ width: "100%", padding: "0px" }}
                                //                 alt="Sample"
                                //                 src={row.image}
                                //             />
                                //         </div>
                                //         <div className="col-9">
                                //             <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{row.name}</h5>
                                //             <p style={{ color: "#666", fontSize: "12px", padding: "0px" }}>{row.address}</p>
                                //             <p style={{ color: "#FF1843", fontSize: "12px", padding: "0px" }}>Available Deals</p>
                                //         </div>
                                //     </div>
                                // </Link>
                                <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.id}`}>
                                    <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px", overflow: "hidden", margin: "10px" }}>
                                        <div className="col-3" style={{ padding: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                                                <img
                                                    className="border-radius-15"
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "15px",
                                                        marginLeft: "5px"
                                                    }}
                                                    alt="Sample"
                                                    src={row.image}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-9" style={{ padding: "15px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <p style={{ color: "#2B2B2B", margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{row.name}</p>
                                            <p style={{ color: "#666", fontSize: "12px", margin: "5px 0" }}>{row.address}</p>
                                            <p style={{ color: "#FF1843", fontSize: "14px", margin: "0", fontWeight: "500" }}>Available Deals</p>
                                        </div>
                                    </div>
                                </Link>
                            ))) : <Spinner animation="border" />
                }
            </div>
            <NavBarBottom />

        </>
    )
}
export default Companies;