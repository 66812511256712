import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { GoogleLogin } from '@react-oauth/google';


function SignIn() {

    const navigate = useNavigate();

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordShown, setPasswordShown] = useState(false)

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [validationError, setValidationError] = useState({})
    const [googleSignIn, setGoogleSignIn] = useState(false)

    const handleGoogleSignIn = () => {
        setGoogleSignIn(true)
    }

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            // Send the credential to your backend
            const response = await axios.post('https://api.bulkbuyme.com/public/api/google-login', {
                credential: credentialResponse.credential
            });

            const { id, role } = response.data.user;

            if (role === "USER") {
                localStorage.setItem('user_logged_in', true);
                localStorage.setItem('user_id', id);
                Swal.fire('Login Successful');
                navigate("/home");
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid account type!',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Google sign-in failed. Please try again.',
            });
        }
    };

    const handleGoogleFailure = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Google sign-in was unsuccessful. Please try again.',
        });
    };

    const login = async (e) => {

        e.preventDefault();

        const formData = new FormData()

        formData.append('email', email)
        formData.append('password', password)

        await axios.post('https://api.bulkbuyme.com/public/api/login', formData).then(({ data }) => {
            //console.log(data.user)
            const { id, role } = data.user

            if (role == "USER") {
                localStorage.setItem('user_logged_in', true)
                localStorage.setItem('user_id', id)
                //console.log(localStorage.getItem("user"))
                Swal.fire('Login Successful')
                navigate("/home")

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid Email or Password !!',
                })
            }
            // navigate("/home")
        }).catch(({ response }) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Email or Password !!',
            })
        })
        /* localStorage.setItem('user_logged_in', true)
         localStorage.setItem('email', email)
         navigate("/home")*/
    }

    return (
        <>
            <Container style={{ backgroundColor: "#FFF" }} fluid>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <a href="https://bulkbuyme.com" rel="noopener noreferrer">
                            <img src="./bulkbuyme.png" alt="Logo" style={{ width: "100px", marginBottom: "10px" }} />
                        </a>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <h3>Sign in</h3>
                        <hr />
                    </div>
                </div>
                <Form onSubmit={login}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Email
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    required
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword">
                                    Password
                                </Label>
                                <div style={{ position: "relative" }}>
                                    <Input
                                        id="examplePassword"
                                        name="password"
                                        required
                                        placeholder="Password"
                                        type={passwordShown ? "text" : "password"}
                                        value={password}
                                        onChange={(event) => {
                                            setPassword(event.target.value)
                                        }}
                                    />
                                    <a style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", color: "#192a51" }} onClick={togglePassword}>
                                        <FontAwesomeIcon size="sm" icon={faEye} />
                                    </a>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="logout-button border-radius-15">
                        Sign in
                    </Button>
                </Form>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <hr />
                        {/* <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleFailure}
                            useOneTap
                        /> */}
                        <p style={{ color: "#666", marginTop: "20px" }}>Forgot Password ? <a style={{ textDecoration: "none", color: "red" }} href="#/reset">Reset</a> <br /> Don't have an account? <a style={{ textDecoration: "none", color: "red" }} href="#/signup">Sign up</a> </p>
                    </div>
                </div>
                {/* <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <hr />
                        <p style={{ color: "#666" }}>Forget Password ? <a style={{ textDecoration: "none", color: "red" }} href="#/reset">Reset</a> <br /> Don't have an account? <a style={{ textDecoration: "none", color: "red" }} href="#/signup">Sign up</a> </p>
                    </div>
                </div> */}
                <NavBarBottom />
            </Container>
        </>
    )
}
export default SignIn;
