// src/HomeScreenPopup.js
import React, { useState, useEffect } from 'react';

const HomeScreenPopup = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [popupWidth, setPopupWidth] = useState('300px'); // Default width

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            window.deferredPrompt = e;
            setIsVisible(true); // Show the popup
        };

        const handleResize = () => {
            // Set width to 90% for mobile/tablet, otherwise 300px
            setPopupWidth(window.innerWidth < 768 ? '90%' : '300px');
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('resize', handleResize); // Add resize event listener

        // Initial width setting
        handleResize();

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('resize', handleResize); // Clean up
        };
    }, []);

    const handleAddToHomeScreen = () => {
        if (window.deferredPrompt) {
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User added to home screen');
                } else {
                    console.log('User did not add to home screen');
                }
                window.deferredPrompt = null;
                setIsVisible(false); // Hide the popup
            });
        }
    };

    return (
        isVisible && (
            <div style={{ ...popupStyle, width: popupWidth }}> {/* Use responsive width */}
                {/* Close button positioned in the top right corner */}
                <button style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#fff',
                    padding: '3px 6px',
                    border: 'none',
                    borderRadius: '5px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    backgroundColor: '#000',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
                }} onClick={() => setIsVisible(false)}>X</button>
                
                {/* Main content of the popup */}
                <h5 style={{ margin: '0 0 10px', textAlign: 'left' }}>Add to Home screen</h5>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 20px', textAlign: 'left' }}>
                    {/* Circular logo */}
                    <img src="logo.png" alt="Logo" style={{
                        width: '40px', // Adjust size as needed
                        height: '40px',
                        borderRadius: '50%', // Make it circular
                        marginRight: '10px', // Space between logo and text
                    }} />
                    {/* Text next to the logo */}
                    <p style={{ margin: '0' }}>BulkBuyMe</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <button style={{
                        color: '#fff',
                        padding: '10px 20px',
                        margin: '5px',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        backgroundColor: '#e88b24', // Updated color
                        boxShadow: '0 2px 5px rgba(232, 139, 36, 0.3)',
                    }} onClick={handleAddToHomeScreen}>Add</button>
                </div>
            </div>
        )
    );
};

// Updated popupStyle for positioning under the screen with CSS animation
const popupStyle = {
    position: 'fixed',
    bottom: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    color: '#343a40',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    textAlign: 'center',
    animation: 'slideIn 0.5s ease forwards', // Added animation property
};

// Add the keyframes for the animation in a <style> tag
const style = document.createElement('style');
style.innerHTML = `
    @keyframes slideIn {
        0% {
            transform: translate(-50%, 100%); // Start from below the screen
            opacity: 0; // Start invisible
        }
        100% {
            transform: translate(-50%, 0); // End at original position
            opacity: 1; // End visible
        }
    }
`;
document.head.appendChild(style);

export default HomeScreenPopup;
