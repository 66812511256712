import { faArrowLeft, faList, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from "sweetalert2";
import NavBarBottom from "../NavBarBottom";
import { QRCodeSVG } from 'qrcode.react';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function CompanyDealCatInfo() {

    const navigate = useNavigate()
    const [user, setUser] = useState()

    const { id } = useParams()

    const [staff, setStaffs] = useState([]);
    const [options, setDealOptions] = useState([]);

    const [selectedDealoption, setSelectedDealoption] = useState("");

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [price, setPrice] = useState("")
    const [qty, setQty] = useState("")
    const [img, setImage] = useState("")
    const [saving, setSaving] = useState("")
    const [com_id, setComId] = useState("")
    const [dealid, setDeal] = useState("")
    const [terms, setTerms] = useState("")
    const [validon, setValidDays] = useState("")

    const [cusid, setCustomer] = useState("1")

    const [salesid, setSalesStaff] = useState("0")
    const [qrValue, setQrValue] = useState("1ALKJDP6HDB");
    const [titlepopup, setTitlePopup] = useState("")

    const { className } = ""
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [open, setOpen] = useState('');
    const toggle2 = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const [approval, setApproval] = useState(false);
    const [isCheckingApproval, setIsCheckingApproval] = useState(false);

    useEffect(() => {
        let intervalId;

        if (modal) {
            // Start the interval to check every 5 seconds
            intervalId = setInterval(() => {
                checkApprovalStatus();
                console.log(checkApprovalStatus());
            }, 5000); // 5000 milliseconds = 5 seconds
        }

        // Clean up the interval when the modal closes or component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [modal, qrValue]);

    useEffect(() => {
        if (approval) {
          const timer = setTimeout(() => {
            navigate('/profile');
          }, 5000);
    
          return () => clearTimeout(timer);
        }
      }, [approval, navigate]);

    useEffect(() => {
        console.log(localStorage.getItem("customerid"))
        fetchDeal()
        fetchDealOptions()
    }, [])


    const handleOptionChange = (event) => {
        setSelectedDealoption(event.target.value);
        fetchDealById(event.target.value)
        //alert(price)        
    };


    function getExpiryDate(numOfDays) {

        const date = new Date();
        date.setDate(date.getDate() + numOfDays);
        const m = date.getMonth() + 1;
        const expdate = date.getFullYear() + "-" + m + "-" + date.getDate();
        return expdate;
    }

    const fetchDeal = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deals/${id}`).then(({ data }) => {
            const { title, description, duration_days, price, qty, image, res_id, saving, term_cond, valid_days } = data.deal
            setTitle(title)
            setTitlePopup(title)
            setDescription(description)
            setDuration(duration_days)
            setPrice(price)
            setQty(qty)
            setImage(image)
            setDeal(id)
            setSelectedDealoption(id)
            setComId(res_id)
            setSaving(saving)
            fetchSalesStaff(res_id)
            setTerms(term_cond)
            setValidDays(valid_days)

        }).catch(({ response: { data } }) => {

        })
    }

    const fetchDealById = async (did) => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deals/${did}`).then(({ data }) => {
            const { title, description, duration_days, price, qty, image, res_id, saving, valid_days } = data.deal
            setPrice(price)
            setQty(qty)
            setTitlePopup(title)

        }).catch(({ response: { data } }) => {

        })
    }

    const fetchDealOptions = async () => {

        await axios.get(`https://api.bulkbuyme.com/public/api/deals_parent/${id}`).then(({ data }) => {
            setDealOptions(data)

        }).catch(({ response: { data } }) => {

        })
    }

    const generateQRCode = (cusId, dealOption) => {
        // Generate a random 3-digit number
        const randomDigits = Math.floor(100 + Math.random() * 900).toString();

        // Combine customer_id, random digits, and deal_id into a string
        const qrCodeString = `${cusId}A${randomDigits}Z${dealOption}B`;

        // const randomString = Math.random().toString(36).substr(2, 5).toUpperCase();
        // return `${cusId}${dealOption}${randomString}`;
        return qrCodeString;
    };

    const handleBuyNowClick = () => {
        const cus_id = localStorage.getItem("customerid");
        if (cus_id) {
            // User is logged in, generate QR code and open modal
            const newQrValue = generateQRCode(cus_id, selectedDealoption);
            setQrValue(newQrValue);
            toggle();
        } else {
            // User is not logged in, redirect to signup page
            navigate("/signup");
        }
    };

    const checkApprovalStatus = async () => {
        setIsCheckingApproval(true);
        try {
            const response = await axios.get(`https://api.bulkbuyme.com/public/api/check-approval/${qrValue}`);
            console.log(response.data.approved);
            setApproval(response.data.approved);
        } catch (error) {
            console.error("Error checking approval status:", error);
            setApproval(false);
        } finally {
            setIsCheckingApproval(false);
        }
    };


    const BuyDeal = async (e) => {
        e.preventDefault();
        const cus_id = localStorage.getItem("customerid");
        if (cus_id) {
            setCustomer(cus_id);

            const formData = new FormData()

            formData.append('customer_id', cus_id)
            formData.append('deal_id', selectedDealoption)
            formData.append('remain', qty)
            formData.append('sales_staff_id', salesid)

            const expirydate = getExpiryDate(duration)
            formData.append('expiry_date', expirydate)

            await axios.post(`https://api.bulkbuyme.com/public/api/customer_deals`, formData).then(({ data }) => {
                Swal.fire('Your Deal has been send for approval')
                navigate("/home")
            }).catch(({ response }) => {
                console.log(response)
            })
        } else {
            navigate("/signup")
        }
    }

    const fetchSalesStaff = async (cid) => {
        await axios.get(`https://api.bulkbuyme.com/public/api/salesstaffcomp/${cid}`).
            then(({ data }) => {
                setStaffs(data)
            });
    }

    const notify = () => {

        toast.success("Approval granted! You can proceed to confirm & pay.");
    };

    return (
        <>
            <div className="cd-my-card">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#e88b24", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>
                {/* <div className="row border" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="col-3" style={{ textAlign: "center", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a style={{ color: "#666" }} href={`#/companydeals/${com_id}`}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-6" style={{ paddingTop: "10px" }}>
                        <h3 style={{ color: "#666" }}>Deal</h3>
                    </div>
                </div> */}
                <div className="row border gr border-radius-25 shadow deal-box" style={{ marginTop: "15%" }}>
                    <div style={{ textAlign: "center" }} className="col-6">
                        <img style={{ width: "100%", padding: "0px" }}
                            alt="Sample"
                            src={img}
                        />
                        {/* <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginTop: "5px" }}>* Valid for {duration} days</p> */}
                    </div>
                    {/* <div className="col-6">
                         <p style={{color:"#FFF", textAlign:"justify"}}>{description}</p>                        
                    </div> */}
                </div>

                <div className="row block" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                        {/* <h3 className="">Your Estimated Saving is <span style={{color:"red"}}>{saving}</span><span style={{fontSize:"14px"}}>/ AED</span></h3> */}

                        {/* <h1>{title}</h1> */}
                        <h2>
                            <input type="radio" name="dg" value={dealid} onChange={handleOptionChange} /> {title}
                            {saving && saving !== "null"  && saving !== "0" && saving.trim() !== "" && <span style={{ color: "red", fontSize: "16px",   textDecoration: 'line-through' }}> / {saving}</span>}
                        </h2>
                        {
                            options.length > 0 && (options.map((row, key) => (
                                <h2>
                                    <input type="radio" name="dg" value={row.id} onChange={handleOptionChange} /> {row.title}
                                    {saving && saving !== "null"  && saving !== "0" && saving.trim() !== "" && <span style={{ color: "red", fontSize: "16px", textDecoration: 'line-through' }}> / {saving}</span>}
                                </h2>
                            )))
                        }
                        <p>Valid for {duration} days</p>
                        <p>* Valid on {validon}</p>
                    </div>
                </div>
                <div className="row block" style={{ marginTop: "20px" }}>
                    {/* <div className="col-12">
                        <h5 style={{ color: "green" }}>Description</h5>
                        <p>{description}</p>
                    </div> */}
                    <div className="col-12">
                        <Accordion open={open} toggle={toggle2}>
                            <AccordionItem style={{ padding: "0" }}>
                                <AccordionHeader targetId="1">
                                    <h5 style={{ color: "green" }}>Description</h5>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <p>{description}</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem style={{ padding: "0" }}>
                                <AccordionHeader targetId="2">
                                    <h5 style={{ color: "green" }}>Terms & Conditions</h5>
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <p style={{ margin: "0", padding: "0", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: terms }} />
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

                <div className="row border-radius-25 shadow deal-box">
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <h1>{price} <span style={{ fontSize: "14px" }}>/ AED</span></h1>
                    </div>
                    <div className="col-6">
                        <Button onClick={handleBuyNowClick} className="buy-button gr-orange border-radius-25">
                            Buy Now
                        </Button>
                    </div>
                </div>
                <div>
                    <Modal isOpen={modal} toggle={toggle} className={className} centered={true}>
                        <ModalHeader toggle={toggle}>
                            Pay at Store
                        </ModalHeader>
                        <ModalBody>
                            {/* <Row>
                                <Col xs={12}><h6>Cash</h6></Col>
                            </Row>
                            <Row>
                                <Form onSubmit={BuyDeal}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input required type="select" onChange={(event) => {
                                                setSalesStaff(event.target.value)
                                            }}
                                            >
                                                <option value="">Select Sales Person</option>
                                                {
                                                    staff.length > 0 && staff.map((row, key) => (
                                                        <option value={row.id}> {row.name} </option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={3} style={{ textAlign: "right" }}>
                                        <Button className="border-radius-15 gr">
                                            Confirm & Pay
                                        </Button>
                                    </Col>
                                </Form>
                            </Row> */}

                            {/* <Row>
                                <Col xs={12}><hr /></Col>
                            </Row> */}
                            <Row>
                                <Col xs={12} style={{ textAlign: "center" }}><h4>{titlepopup}</h4></Col>
                                <Col xs={12} style={{ textAlign: "center" }}><h4 style={{ fontWeight: "bold" }}>Amount : {price} AED </h4></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><hr /></Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12}><p style={{ color: "#666", fontSize: "14px", textAlign: "center" }}>Have a Staff member at the store scan or enter this code to accept your payment</p></Col>
                            </Row> */}
                            <Form onSubmit={BuyDeal}>
                                <Row className="align-items-center">
                                    <Col xs={12} style={{ textAlign: "center" }}>
                                        <p style={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}>Show this QR / Code to a staff member</p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col xs={6} style={{ textAlign: "center", borderRight: '1px solid #dee2e6' }}>
                                        <QRCodeSVG value={qrValue} size={128} />
                                    </Col>
                                    <Col xs={6} style={{ textAlign: "center" }}>
                                        <h3 style={{ fontWeight: "bold" }}>{qrValue}</h3>
                                    </Col>
                                    <Col xs={12}><hr /></Col>
                                    {/* <Col xs={12} md={3} style={{ textAlign: "right" }}>
                                        <Button className="border-radius-15 gr">
                                            Confirm & Pay
                                        </Button>
                                    </Col> */}
                                </Row>
                            </Form>
                            <Row className="mt-3">
                                <Col xs={12} style={{ textAlign: "center" }}>

                                    {isCheckingApproval ? (
                                        <>
                                            <div className="spinner"></div>
                                            <span className="ml-2">Checking approval status...</span>
                                        </>
                                    ) : approval ? (
                                        <>
                                            <span className="ml-2">Success! Your deal is live.</span>
                                            <Link to="/profile">Explore your deal</Link>
                                        </>
                                    ) : (
                                        <>
                                            <div className="spinner"></div>
                                            <span className="ml-2">Waiting for approval...</span>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </ModalBody>

                        <style jsx>{` .spinner {
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    border: 3px solid rgba(0, 0, 0, 0.3);
                                    border-radius: 50%;
                                    border-top-color: #000;
                                    animation: spin 1s ease-in-out infinite;
                                    margin-right: 10px;
                                    }

                                    @keyframes spin {
                                    to { transform: rotate(360deg); }
                                    }c`}
                        </style>
                    </Modal>
                </div>
                <NavBarBottom />
            </div >
        </>
    )
}
export default CompanyDealCatInfo;