import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner, CardImg, CardText } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import MyDealsByCom from "./MyDealsByCom";
import SignIn from "../auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./DealList";
import PopularDeals from "./PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPhoneAlt, faClose, faMapMarkerAlt, faMobilePhone } from "@fortawesome/free-solid-svg-icons";
import DealOptions from "./DealOptions";


function CompanieDeals() {

    const { id } = useParams()

    const [user, setUser] = useState();
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [img, setImage] = useState("");

    useEffect(() => {

        fetchDeals()
        fetchCompany()

    }, [])

    const fetchCompany = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/restaurants/${id}`).
            then(({ data }) => {
                const { name, image, address, contact } = data.restaurant
                setTitle(name)
                setImage(image)
                setAddress(address)
                setContact(contact)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) { });
    }

    const fetchDeals = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deals/active/${id}`).
            then(({ data }) => {
                setDeals(data)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) {

            });
    }

    const fetchOptions = async () => {
        //alert("dsd")
    }

    const CalendarIcon = () => (

        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>

    );

    return (
        <>
            <div className="cd-my-card">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#e88b24", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">

                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>

                <CardImg top src={img} alt="Card Image" />

                <div className="overlay-text" style={{ backgroundColor: "#f8f9fa", padding: "15px", borderRadius: "8px" }}>
                    <Row>
                        <div className="col-7">
                            <h2 className="heading" style={{ fontSize: "24px", fontWeight: "600", color: "#343a40" }}>{title}</h2>
                        </div>
                        <div className="col-5" style={{ textAlign: "right" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>
                                <a href={`tel:${contact}`} style={{ marginRight: "10px" }}>
                                    <FontAwesomeIcon icon={faPhoneAlt} style={{ width: '24px', height: '24px', color: '#e88b24' }} />
                                </a>                                
                                <a href={`https://www.google.com/maps/search/?api=1&query=${address}`} style={{ marginRight: "10px" }}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ width: '24px', height: '24px', color: '#e88b24' }} />
                                </a>                               
                                
                            </div>
                        </div>
                    </Row>
                </div>
                <CardBody className="custom-card-body" style={{ borderTop: "1px solid #dee2e6", padding: "10px" }}>
                    <CardText style={{ fontSize: "14px", color: "#495057" }}>
                        {address}
                    </CardText>
                </CardBody>
            </div>

            <Container className="" style={{ borderTop: "1px solid #c1c1c1" }}>

                <MyDealsByCom resid={id} style={{ marginTop: "0px" }} />
                <div className="row title" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                        <h6>Available Deals</h6>
                    </div>
                </div>
                <div>
                    {
                        loading ?
                            deals.length > 0 && (
                                deals.map((row, key) => (
                                    <Link style={{ textDecoration: "none" }} to={`/dealcatinfo/${row.id}`}>
                                        <div className="row border gr border-radius-25 shadow deal-box" style={{ height: "auto" }}>
                                            <div style={{ textAlign: "center" }} className="col-4">
                                                <img style={{ width: "100%", padding: "0px" }}
                                                    alt="Sample"
                                                    src={row.image}
                                                />
                                                <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginBottom: "0px" }}>* Valid for {row.duration_days} days</p>
                                                <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginBottom: "0px" }}>
                                                    {row.valid_days.toLowerCase() !== 'all week' && (
                                                        <span> <CalendarIcon /> {row.valid_days.toLowerCase() === 'Weekend Deal' ? 'Weekend Deal' : 'Weekday Deal'}</span>
                                                    )}
                                                </p>
                                            </div>
                                            <div className="col-8" style={{ textAlign: "left" }}>
                                                <h6 style={{ color: "#FFF", fontWeight: "bold", fontSize: "16px", marginTop: "10px", marginLeft: "10px" }}>
                                                    {row.title}
                                                    {row.saving && row.saving !== "null" && row.saving !== "0" && row.saving.trim() !== "" && <p style={{
                                                        textDecoration: 'line-through',
                                                        color: 'white',
                                                        margin: '5px 0'
                                                    }}>
                                                        {row.saving} AED
                                                    </p>
                                                    }
                                                </h6>
                                                <DealOptions id={row.id} />
                                                <button style={{ marginTop: "5px" }} className="buy-button gr-orange border-radius-25">Buy Now</button>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            )
                            : <Spinner animation="border" />
                    }
                </div>

            </Container>
            <NavBarBottom />
        </>
    )
}
export default CompanieDeals;
