import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import MyDeals from "./MyDeals";
import SignIn from "../auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./DealList";
import PopularDeals from "./PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


function CompanieDealCat() {
    const { id } = useParams()

    const [user, setUser] = useState();
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [img, setImage] = useState("");

    useEffect(() => {

        fetchDeals()
        fetchCompany()

    }, [])

    const fetchCompany = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/restaurants/${id}`).
            then(({ data }) => {
                const { name, image } = data.restaurant
                setTitle(name)
                setImage(image)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) {

            });
    }

    const fetchDeals = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/catdeals`).
            then(({ data }) => {
                setDeals(data)
                //console.log(data)
                setLoading(true);
            }).catch(function (error) {

            });
    }

    return (
        <>
            <Container className="">
                <div className="row border" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="col-3" style={{ textAlign: "center", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a style={{ color: "#666" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-6">
                        <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{title}</h5>
                    </div>
                    <div className="col-2">
                        <img className="border-radius-15" style={{ width: "100%", padding: "5px" }}
                            alt="Sample"
                            src={img}
                        />
                    </div>
                </div>
                <div>
                    {
                        loading ?
                            deals.length > 0 && (
                                deals.map((row, key) => (
                                    <Link style={{ textDecoration: "none" }} to={`/dealcatinfo/12`}>
                                        <div className="row border gr border-radius-25 shadow deal-box">
                                            <div className="col-6">
                                                <img style={{ width: "100%", padding: "0px" }}
                                                    alt="Sample"
                                                    src={row.image}
                                                />
                                            </div>
                                            <div className="col-6" style={{ textAlign: "center" }}>
                                                <h4 style={{ color: "#FFF", fontWeight: "bold", marginTop: "10px" }}>{row.title}</h4>
                                                <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginTop: "5px" }}> 10 Coffees for 100 AED</p>
                                                <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginTop: "5px" }}> 10 Coffees for 100 AED</p>
                                            </div>                                            
                                        </div>
                                    </Link>
                                ))
                            )
                            : <Spinner animation="border" />
                    }

                </div>
            </Container>
            <NavBarBottom />

        </>
    )
}
export default CompanieDealCat;