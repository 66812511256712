import React from "react";
import DealList from "./components/deals/DealList";
import { Routes, Route } from "react-router-dom";
import DealInfo from "./components/deals/DealInfo";
import DealHistory from "./components/deals/DealHistory";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import Profile from "./components/Profile";
import Menu from "./components/Menu";
import DealRedeem from "./components/deals/DealRedeem";
import ForgetPassword from "./components/auth/ForgetPassword";
import Home from "./components/Home";
import Companies from "./components/Companies";
import CompanieDeals from "./components/deals/CompanyDeals";
import Verify from "./components/auth/Verify";
import CompanieDealCat from "./components/deals/CompanyDealCat";
import CompanyDealCatInfo from "./components/deals/CompanyDealCatInfo";
import HomeScreenPopup from "./components/HomeScreenPopup";
import ResetLink from "./components/auth/ResetLink";
// import './mobile-centric-layout.css';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="comp/:id" element={<Companies />} />
        <Route path="companydeals/:id" element={<CompanieDeals />} />
        <Route path="companydealcats/:id" element={<CompanieDealCat />} />
        <Route path="dealcatinfo/:id" element={<CompanyDealCatInfo />} />
        <Route path="dealinfo/:id" element={<DealInfo />} />
        <Route path="dealredeem/:id" element={<DealRedeem />} />
        <Route path="history" element={<DealHistory />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="profile" element={<Profile />} />
        <Route path="menu" element={<Menu />} />
        <Route path="reset" element={<ForgetPassword />} />
        <Route path="verify" element={<Verify />} />
        <Route path="reset_link/:token" element={<ResetLink />} />
      </Routes>
      <HomeScreenPopup />
    </div>

  )
}

export default App;