// SearchBar.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const navigate = useNavigate();

  const handleSearchClick = () => {
    navigate("/comp/1"); // Redirect to specific page when search is clicked
  };

  const searchBarContainerStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%", // Set full width for the container
    padding: "10px 0", // Add padding for spacing at the top and bottom
  };

  const inputStyle = {
    padding: "10px 15px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "100%", // Set input to take 100% of the container width
    maxWidth: "600px", // Maximum width for better responsiveness
    paddingLeft: "40px", // Extra space for the icon inside
    fontSize: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "0 20px", // Add side margins for better spacing on smaller screens
  };

  const iconStyle = {
    position: "absolute",
    left: "25px",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "16px",
    color: "#888",
  };

  const containerWrapperStyle = {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div style={searchBarContainerStyle}>
      <div style={containerWrapperStyle}>
        <FontAwesomeIcon icon={faSearch} style={iconStyle} />
        <input
          type="text"
          placeholder="Search..."
          onClick={handleSearchClick}
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default SearchBar;
