import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "./SearchBar";
import axios from "axios";

function NavBarTop() {
  const [userid, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [fname, setFirstName] = useState("");

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (user_id) {
      setUserId(user_id);
      fetchCustomer();
    }

    const today = new Date().getHours();

    if (today < 12) {
      setGreeting("Good Morning");
    } else if (today < 16) {
      setGreeting("Good Afternoon");
    } else if (today < 24) {
      setGreeting("Good Evening");
    }
  }, []);

  const fetchCustomer = async () => {
    await axios
      .get(`https://api.bulkbuyme.com/public/api/customerinfo/${localStorage.getItem("user_id")}`)
      .then(({ data }) => {
        const { id, first_name, last_name } = data.customer;
        setFirstName(first_name);
        localStorage.setItem("customerid", id);
      })
      .catch(({ response }) => {
        // Handle errors here
      });
  }

  return (
    <div className="custom-navbar" style={{ backgroundColor: "#FFF"}}>
      <div className="row" style={{ padding: "10px", alignItems: "center", borderBottom: "0.5px solid #e88b24" }}>
        <div className="col-5" style={{ textAlign: "left" }}>
          <img style={{ width: "150px" }} alt="Sample" src='./bulkbuyme.png' />
        </div>
        <div className="col-1"></div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <div style={{ display: "inline-block", backgroundColor: "#e88b24", padding: "5px 10px", borderRadius: "5px" }}>
            <a href="http://merchantportal.bulkbuyme.com/#/partner" style={{ color: "#FFF", textDecoration: "none", fontWeight: "bold", fontSize: "14px" }} 
               onMouseOver={e => e.currentTarget.style.color = '#007BFF'} 
               onMouseOut={e => e.currentTarget.style.color = '#FFF'}>
              Partner with us
            </a>
          </div>
          {/* <a href="#/profile" style={{ color: "#000", textDecoration: "none", marginLeft: "15px", transition: "color 0.3s" }} 
             onMouseOver={e => e.currentTarget.style.color = '#007BFF'} 
             onMouseOut={e => e.currentTarget.style.color = '#000'}>
            <FontAwesomeIcon size="lg" icon={faUserLock} />
          </a> */}
        </div>
      </div>
      <SearchBar />
    </div>
  );
}

export default NavBarTop;
