import { faArrowLeft, faList, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from "sweetalert2";
import NavBarBottom from "../NavBarBottom";

function DealInfo() {

    const navigate = useNavigate()
    const [user, setUser] = useState()

    const { id } = useParams()

    const [staff, setStaffs] = useState([]);

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [price, setPrice] = useState("")
    const [qty, setQty] = useState("")
    const [img, setImage] = useState("")
    const [saving, setSaving] = useState("")
    const [com_id, setComId] = useState("")
    const [dealid, setDeal] = useState("")

    const [cusid, setCustomer] = useState("1")

    const [salesid, setSalesStaff] = useState("0")

    const { className } = ""
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    useEffect(() => {
        console.log(localStorage.getItem("customerid"))
        fetchDeal()

    }, [])


    function getExpiryDate(numOfDays) {

        const date = new Date();
        date.setDate(date.getDate() + numOfDays);
        const m = date.getMonth() + 1;
        const expdate = date.getFullYear() + "-" + m + "-" + date.getDate();
        return expdate;
    }

    const fetchDeal = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deals/${id}`).then(({ data }) => {
            const { title, description, duration_days, price, qty, image, res_id, saving } = data.deal
            setTitle(title)
            setDescription(description)
            setDuration(duration_days)
            setPrice(price)
            setQty(qty)
            setImage(image)
            setDeal(id)
            setComId(res_id)

            setSaving(saving)

            fetchSalesStaff(res_id)

        }).catch(({ response: { data } }) => {

        })
    }

    const BuyDeal = async (e) => {
        e.preventDefault();
        const cus_id = localStorage.getItem("customerid");
        if (cus_id) {
            setCustomer(cus_id);

            const formData = new FormData()

            formData.append('customer_id', cus_id)
            formData.append('deal_id', dealid)
            formData.append('remain', qty)
            formData.append('sales_staff_id', salesid)

            const expirydate = getExpiryDate(duration)
            formData.append('expiry_date', expirydate)

            await axios.post(`https://api.bulkbuyme.com/public/api/customer_deals`, formData).then(({ data }) => {
                Swal.fire('Your Deal has been send for approval')
                navigate("/home")
            }).catch(({ response }) => {
                console.log(response)
            })
        } else {
            navigate("/signup")
        }
    }

    const fetchSalesStaff = async (cid) => {
        await axios.get(`https://api.bulkbuyme.com/public/api/salesstaffcomp/${cid}`).
            then(({ data }) => {
                setStaffs(data)
            });
    }
    return (
        <>
            <div className="cd-my-card">
                <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", position: "absolute", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#fff" }}>Deals</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>
                {/* <div className="row border" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="col-3" style={{ textAlign: "center", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a style={{ color: "#666" }} href={`#/home`}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-6" style={{ paddingTop: "10px" }}>
                        <h3 style={{ color: "#666" }}>Deal</h3>
                    </div>
                </div> */}

                <div className="row border gr border-radius-25 shadow deal-box">
                    <div className="col-6">
                        <img style={{ width: "100%", padding: "0px" }}
                            alt="Sample"
                            src={img}
                        />
                    </div>

                    <div className="col-6">
                        <h2 style={{ color: "#FFF", fontWeight: "bold", marginTop: "10px" }}>{title}</h2>
                    </div>
                </div>

                <div className="row block" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                        {/* <h3 className="">Your Estimated Saving is <span style={{color:"red"}}>{saving}</span><span style={{fontSize:"14px"}}>/ AED</span></h3> */}

                        <h1>{title}</h1>
                        <p>Valid for {duration} days</p>
                    </div>
                </div>

                <div className="row block" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                        <h5 style={{ color: "green" }}>Description</h5>
                        <p>{description}</p>
                    </div>
                    <div className="col-12">
                        <h5 style={{ color: "green" }}>Terms & Conditions</h5>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Valid for dine in and Take away</p>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Offer cannot be exchanged for cash</p>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Offer is not valid with any other promotions or discount</p>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Offer Is Valid for 3 month from the date of purchase</p>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Offer must be present at the time of purchase</p>
                        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}> * Offer are Valid at the Location Specified</p>
                    </div>
                </div>

                <div className="row border-radius-25 shadow deal-box">
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <h1>{price} <span style={{ fontSize: "14px" }}>/ AED</span></h1>
                    </div>
                    <div className="col-6">
                        {/* <Form onSubmit={BuyDeal}> */}
                        <Button onClick={toggle} className="buy-button gr-orange border-radius-25">Buy Now</Button>
                        {/* </Form> */}
                    </div>
                </div>
                <div>
                    <Modal isOpen={modal} toggle={toggle} className={className} centered={true}>
                        <ModalHeader toggle={toggle}>
                            Choose Payment
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs={12}><h6>Cash</h6></Col>
                            </Row>
                            <Row>
                                <Form onSubmit={BuyDeal}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input required type="select" onChange={(event) => {
                                                setSalesStaff(event.target.value)
                                            }}
                                            >
                                                <option value="">Select Sales Person</option>
                                                {
                                                    staff.length > 0 && staff.map((row, key) => (
                                                        <option value={row.id}> {row.name} </option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={3} style={{ textAlign: "right" }}>
                                        <Button className="border-radius-15 gr">
                                            Confirm & Pay
                                        </Button>
                                    </Col>
                                </Form>
                            </Row>                            
                            <Row>
                                <Col xs={12}><hr /></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><h6>Pay at Store</h6></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><p style={{ color: "#CCC" }}>Not Available Right Now</p></Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>
                <NavBarBottom />
            </div>
        </>
    )
}
export default DealInfo;