import React from "react";
import { faUser, faHome, faHistory, faBurger, faPlus, faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavBarBottom() {

    const styles = {
        navItem: {
            textAlign: 'center',
        },
        link: {
            color: '#5E5E5E',
            textDecoration: 'none',
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#E88B24',
            margin: '0 auto',
        },
        icon: {
            color: 'white',
        },
        text: {
            fontSize: '12px',
            marginTop: '5px',
        },
    };

    return (
        <>
            <div style={{ height: "70px" }}></div>
            <nav className="navbar fixed-bottom shadow navbar-light" role="navigation">
                <Nav className="w-100">
                    <div className="d-flex flex-row justify-content-around w-100">
                        {/* <NavItem style={{ textAlign: "center" }}>
                            <NavLink className="nav-link" activeClassName="active">
                                <a href="#/home" style={{ color: "#5E5E5E", textDecoration: "none" }}>
                                    <div className="row d-flex flex-column justify-content-center align-items-center">
                                        <FontAwesomeIcon size="sm" icon={faHome} />
                                        <div style={{ fontSize: "12px" }}>Home</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem> */}
                        <NavItem style={{ textAlign: 'center' }}>
                            <NavLink>
                                <a href="#/home" style={{ color: '#5E5E5E', textDecoration: 'none' }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFF', // Changed to green as an example
                                            margin: '0 auto'
                                        }}>
                                            <FontAwesomeIcon
                                                size="sm"
                                                icon={faHome}
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', marginTop: '5px' }}>Home</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ textAlign: 'center' }}>
                            <NavLink>
                                <a href="#/profile" style={{ color: '#5E5E5E', textDecoration: 'none' }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFF', // Changed to green as an example
                                            margin: '0 auto'
                                        }}>
                                            <FontAwesomeIcon
                                                icon={faMapLocation}
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', marginTop: '5px' }}>Explore</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem>
                        <NavItem style={styles.navItem}>
                            <NavLink>
                                <a href="#/profile" style={styles.link}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div style={styles.iconContainer}>
                                            <FontAwesomeIcon size="sm" icon={faMapLocation} style={styles.icon} />
                                        </div>
                                        <div style={styles.text}>My Deals</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ textAlign: 'center' }}>
                            <NavLink>
                                <a href="#/history" style={{ color: '#5E5E5E', textDecoration: 'none' }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFF', // Changed to green as an example
                                            margin: '0 auto'
                                        }}>
                                            <FontAwesomeIcon
                                                icon={faHistory}
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', marginTop: '5px' }}>History</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ textAlign: 'center' }}>
                            <NavLink>
                                <a href="#/profile" style={{ color: '#5E5E5E', textDecoration: 'none' }}>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFF', // Changed to green as an example
                                            margin: '0 auto'
                                        }}>
                                            <FontAwesomeIcon
                                                icon={faUser}
                                            />
                                        </div>
                                        <div style={{ fontSize: '12px', marginTop: '5px' }}>Profile</div>
                                    </div>
                                </a>
                            </NavLink>
                        </NavItem>
                    </div>
                </Nav>
                {/* Floating button */}
                {/* <a href="#/profile" style={{ color: "#5E5E5E", textDecoration: "none" }}>
                    <button
                        className="btn btn-primary rounded-circle"
                        style={{
                            position: "absolute",
                            bottom: "40px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "56px",
                            height: "56px",
                            backgroundColor: "#E88B24",
                            color: "#fff",
                            border: "none",
                            fontSize: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0 4px 8px rgba(0,0,0,0.3)"
                        }}
                    >
                        <FontAwesomeIcon />
                        <div style={{ fontSize: "12px" }}>My Deals</div>
                    </button>
                </a> */}
            </nav>
        </>
    );
}

export default NavBarBottom;
